// Core
import { Navigate, RouteObject } from 'react-router-dom'

// App
import App from '@src/app'
import { AppRouterErrorBoundary } from '@src/components'

// Internal
import userRoutes from '../pages/user/lib/routes'
import adminRoutes from '../pages/admin/lib/routes'
import authRoutes from '../pages/authentication/lib/routes'

// Routes
const appRoutes: RouteObject[] = [
  {
    path: '/',
    element: <App />,
    ErrorBoundary: AppRouterErrorBoundary,
    children: [
      {
        index: true,
        element: <Navigate to='/user' replace />
      },
      authRoutes,
      userRoutes,
      adminRoutes
    ]
  }
]

export default appRoutes
