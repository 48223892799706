// Core
import { QueryClient } from '@tanstack/react-query'
import { AxiosError, isAxiosError } from 'axios'

// App
import { ResponseType } from '@src/api/types/common'
import { useAuthStore, useUserStore } from '../stores'

// Handle authorization error
const handleAuthorizationError = (error: Error) => {
  const axiosError = error as AxiosError<ResponseType>

  if (axiosError.response?.status === 401) {
    console.error('Token expired ...')

    // Reset auth store
    const { setIsLoggedIn, removeToken } = useAuthStore.getState()
    setIsLoggedIn(false)
    removeToken()

    // Reset user store
    const { setIsFetched, removeUser } = useUserStore.getState()
    setIsFetched(false)
    removeUser()
  }
}

// Handle delay value
const handleDelayRetry = (failureCount: number) => failureCount * 1000 + Math.random() * 1000

// Query client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      placeholderData: (previousData: unknown) => previousData,
      retryDelay: (failureCount) => handleDelayRetry(failureCount),
      throwOnError(error) {
        handleAuthorizationError(error)
        return false
      }
    },
    mutations: {
      retry: (failureCount, error) => {
        if (failureCount > 3 || !isAxiosError(error)) {
          return false
        }

        const axiosError = error as AxiosError<ResponseType>
        if (
          axiosError.response?.status === 500
          // &&
          // axiosError.response?.data.violations?.find(
          //   (violation) =>
          //     violation.message.toLocaleLowerCase().includes('timeout') ||
          //     violation.message.toLocaleLowerCase().includes('connect')
          // )
        ) {
          return true
        }
        return false
      },
      retryDelay: (failureCount) => handleDelayRetry(failureCount),
      onError: (error) => handleAuthorizationError(error)
    }
  }
})

export default queryClient
