// Core
import ReactDOM from 'react-dom/client'
import { StrictMode, Suspense } from 'react'
import { RouterProvider } from 'react-router-dom'
import { Toaster } from '@src/components/ui/sonner'
import { QueryClientProvider } from '@tanstack/react-query'
import TopBarProgress from 'react-topbar-progress-indicator'
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

// App
import router from '@src/router'
import { queryClient } from '@src/configs'

// Internal
import './styles/index.css'

// Root
ReactDOM.createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <Suspense fallback={<TopBarProgress />}>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
        <Toaster
          theme='light'
          richColors
          closeButton
          toastOptions={{
            duration: 3000,
            className: 'p-3 gap-2',
            classNames: {
              closeButton: 'left-auto right-0 top-0 -translate-y-[10px] translate-x-0'
            }
          }}
        />
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      </QueryClientProvider>
    </Suspense>
  </StrictMode>
)
