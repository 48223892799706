import { devtools } from 'zustand/middleware'
import { create, StateCreator } from 'zustand'

const isUndefined = (value: unknown) => value === undefined

// Types
// States type
interface WebsiteConfigStoreStatesType {
  name: string | null
  logo: {
    id: string
    path: string
  } | null
  banner: {
    id: string
    path: string
  } | null
  theme: string | null
  previewTheme: string | null
}

// Actions type
interface SetWebsiteConfigParamsType {
  name?: string | null
  logo?: {
    id: string
    path: string
  } | null
  banner?: {
    id: string
    path: string
  } | null
  theme?: string | null
  previewTheme?: string | null
}

interface ThemeStoreActionsType {
  setWebsiteConfig: (params: SetWebsiteConfigParamsType) => void
}

// Constants
const DEFAULT_THEME_STORE_STATES: WebsiteConfigStoreStatesType = {
  name: null,
  logo: null,
  banner: null,
  theme: null,
  previewTheme: null
}

// Define store
const websiteConfigStore: StateCreator<WebsiteConfigStoreStatesType & ThemeStoreActionsType> = (set) => {
  return {
    // States
    ...DEFAULT_THEME_STORE_STATES,

    // Actions
    setWebsiteConfig: ({ name, logo, banner, theme, previewTheme }) => {
      set((state) => ({
        name: isUndefined(name) ? state.name : name,
        logo: isUndefined(logo) ? state.logo : logo,
        banner: isUndefined(banner) ? state.banner : banner,
        theme: isUndefined(theme) ? state.theme : theme,
        previewTheme: isUndefined(previewTheme) ? state.previewTheme : previewTheme
      }))
    }
  }
}

const useWebsiteConfigStore = create(
  devtools(websiteConfigStore, {
    name: 'website-config-store'
  })
)

export default useWebsiteConfigStore
