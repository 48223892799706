// Core
import { createBrowserRouter } from 'react-router-dom'

// App
import routes from '@src/app/lib/routes'

// Router
const router = createBrowserRouter(routes)

export default router
