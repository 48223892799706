import { RouteObject } from 'react-router-dom'

// Routes
const userRoutes: RouteObject = {
  path: '/portal',
  lazy: async () => {
    const { default: Component } = await import('@src/app/pages/user')
    return { element: <Component /> }
  },
  children: [
    {
      index: true,
      lazy: async () => {
        const { default: Component } = await import('@src/app/pages/user/pages/home')
        return { element: <Component /> }
      }
    },
    {
      path: '/portal/activities',
      lazy: async () => {
        const { default: Component } = await import('@src/app/pages/user/pages/activities/activities-list')
        return { element: <Component /> }
      }
    },
    {
      path: '/portal/activities/:id/:name',
      lazy: async () => {
        const { default: Component } = await import('@src/app/pages/user/pages/activities/activity-detail')
        return { element: <Component /> }
      }
    }

    // { path: '/portal/activities/:id/:name', title: 'Chi tiết hoạt động', component: ActivitiesDetail },
    // { path: '/portal/activities/donate-blood', title: 'Hiến máu', component: DonateBlood},
    // { path: '/portal/activities/donate-blood/:id/:name', title: 'Chi tiết hiến máu', component: ActivitiesDetail },
  ]
}

export default userRoutes
