// Core
import Axios, { AxiosError, AxiosRequestConfig } from 'axios'

// App
import { useAuthStore } from '@src/stores'
import { commonConfig } from '@src/configs'

// Instance
const AXIOS_INSTANCE = Axios.create({ baseURL: commonConfig.apiEndpoint })

// Request middleware
AXIOS_INSTANCE.interceptors.request.use(async (config) => {
  config.headers.Authorization = `Bearer ${useAuthStore.getState().token ?? ''}`
  // config.headers['Origin'] = commonConfig.siteURL

  // if (config.method === 'post' && config.url?.includes('/exportJoinedOrgs')) {
  //   config.responseType = 'blob'
  //   config.headers['Content-Type'] = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
  // }

  return config
})

// Response middleware
AXIOS_INSTANCE.interceptors.response.use(
  async (response) => response,
  (error) => Promise.reject(error)
)

// Custom client
const customClient = <T>(config: AxiosRequestConfig, options?: AxiosRequestConfig): Promise<T> => {
  const source = Axios.CancelToken.source()
  const promise = AXIOS_INSTANCE({
    ...config,
    ...options
  }).then(({ data, status }) => {
    return data instanceof Blob ? data : { ...data, statusCode: status }
  })

  // @ts-expect-error not exist cancel
  promise.cancel = () => {
    source.cancel('Query was cancelled')
  }
  return promise
}

export { customClient, AXIOS_INSTANCE }
export default customClient

// In some case with react-query and swr you want to be able to override the return error type so you can also do it here like this

export type ErrorType<Error> = AxiosError<Error>

export type BodyType<BodyData> = BodyData

// Or, in case you want to wrap the body type (optional)

// (if the custom instance is processing data before sending it, like changing the case for example)
// export type BodyType<BodyData> = CamelCase<BodyData>;
