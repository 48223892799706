import { Navigate, RouteObject } from 'react-router-dom'

// Routes
const authenticationRoutes: RouteObject = {
  path: '/authentication',
  lazy: async () => {
    const { default: Component } = await import('@src/app/pages/authentication')
    return {
      element: <Component />
    }
  },
  children: [
    {
      index: true,
      element: <Navigate to='/authentication/sign-in' replace />
    },
    {
      path: '/authentication/sign-in',
      lazy: async () => {
        const { default: Component } = await import('@src/app/pages/authentication/pages/sign-in')
        return {
          element: <Component />
        }
      }
    }
  ]
}

export default authenticationRoutes
