// Core
import { toast } from 'sonner'
import { twMerge } from 'tailwind-merge'
import axios, { AxiosError } from 'axios'
import { type ClassValue, clsx } from 'clsx'

// App
import { ResponseType } from '@src/api/types/common'

// Utility list
// Cn
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

// Split full name
export function splitFullName(fullName: string) {
  const parts = fullName.trim().split(/\s+/)
  let lastName = ''
  let middleName = ''
  let firstName = ''

  if (parts.length > 0) {
    lastName = parts[0]
  }

  if (parts.length === 2) {
    firstName = parts[1]
  } else if (parts.length > 2) {
    firstName = parts[parts.length - 1]
    middleName = parts.slice(1, -1).join(' ')
  }

  return {
    firstName,
    middleName,
    lastName
  }
}

// Get name
export function getName({
  type = 'FULL',
  lastName = '',
  middleName = '',
  firstName = ''
}: {
  type?: 'FULL' | 'LAST_AND_MIDDLE'
  firstName?: string
  middleName?: string
  lastName?: string
}) {
  switch (type) {
    case 'LAST_AND_MIDDLE':
      return `${lastName ?? ''}${middleName ? ` ${middleName}` : ''}`
    case 'FULL':
      return `${lastName ? `${lastName} ` : ''}${middleName ? `${middleName} ` : ''}${firstName}`
    default:
      return ''
  }
}

// Is axios error
export function isAxiosError<T = ResponseType>(error: unknown): error is AxiosError<T> {
  return axios.isAxiosError(error)
}

// Extract error message
export function extractErrorMessage(error: unknown) {
  const axiosErrorResponse = isAxiosError(error) ? error.response : null
  const errorMessage =
    // axiosErrorResponse?.data.violations?.[0]?.action?.[0]?.msg ??
    // axiosErrorResponse?.data.violations?.[0].message ??
    axiosErrorResponse?.data.message ?? 'Có lỗi xảy ra, vui lòng thử lại sau'
  return errorMessage
}

// Toast error message
export function toastErrorMessage(error: unknown) {
  toast.error('Thất bại', {
    description: extractErrorMessage(error)
  })
}

// Is axios aborted
export function isAxiosAborted(error: unknown) {
  const axiosError = isAxiosError(error) ? error : null
  return Boolean(axiosError?.config?.signal?.aborted)
}

// Format time to HH:mm:ss
export function formatTime(seconds: number) {
  const hours: number = Math.floor(seconds / 60 / 60)
  const minutes: number = Math.floor(seconds / 60 - hours * 60)
  const remainingSeconds: number = seconds % 60
  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`
}
