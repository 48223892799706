import { DateOnly } from '@src/api/models'
import { create, StateCreator } from 'zustand'

const isUndefined = (value: unknown) => value === undefined

// Types
// States type
interface UserStoreStatesType {
  isFetched: boolean
  address?: string | null
  className?: string | null
  code?: string | null
  dateOfBirth?: DateOnly
  description?: string | null
  email?: string | null
  facultyName?: string | null
  firstName?: string | null
  fullName?: string | null
  gender?: string | null
  id?: number
  lastName?: string | null
  middleName?: string | null
  phoneNumber?: string | null
  roleName?: string | null
  statusText?: string | null
}

// Actions type
interface SetUserParamsType {
  address?: string | null
  className?: string | null
  code?: string | null
  dateOfBirth?: DateOnly
  description?: string | null
  email?: string | null
  facultyName?: string | null
  firstName?: string | null
  fullName?: string | null
  gender?: string | null
  id?: number
  lastName?: string | null
  middleName?: string | null
  phoneNumber?: string | null
  roleName?: string | null
  statusText?: string | null
}

interface UserStoreActionsType {
  setIsFetched: (isFetched: boolean) => void
  setUser: (params: SetUserParamsType) => void
  removeUser: () => void
}

// Constants
const DEFAULT_USER_STORE_STATES: UserStoreStatesType = {
  isFetched: false,
  address: null,
  className: null,
  code: null,
  dateOfBirth: {},
  description: null,
  email: null,
  facultyName: null,
  firstName: null,
  fullName: null,
  gender: null,
  id: 0,
  lastName: null,
  middleName: null,
  phoneNumber: null,
  roleName: null,
  statusText: null
}

// Define store
const userStore: StateCreator<UserStoreStatesType & UserStoreActionsType> = (set) => {
  return {
    // States
    ...DEFAULT_USER_STORE_STATES,

    // Actions
    setIsFetched: (isFetched) =>
      set(() => ({
        isFetched
      })),
    setUser: ({
      address,
      className,
      code,
      dateOfBirth,
      description,
      email,
      facultyName,
      firstName,
      fullName,
      gender,
      id,
      lastName,
      middleName,
      phoneNumber,
      roleName,
      statusText
    }) => {
      // Set user data
      set((state) => ({
        address: isUndefined(address) ? state.address : address,
        className: isUndefined(className) ? state.className : className,
        code: isUndefined(code) ? state.code : code,
        dateOfBirth: isUndefined(dateOfBirth) ? state.dateOfBirth : dateOfBirth,
        description: isUndefined(description) ? state.description : description,
        email: isUndefined(email) ? state.email : email,
        facultyName: isUndefined(facultyName) ? state.facultyName : facultyName,
        firstName: isUndefined(firstName) ? state.firstName : firstName,
        fullName: isUndefined(fullName) ? state.fullName : fullName,
        gender: isUndefined(gender) ? state.gender : gender,
        id: isUndefined(id) ? state.id : id,
        lastName: isUndefined(lastName) ? state.lastName : lastName,
        middleName: isUndefined(middleName) ? state.middleName : middleName,
        phoneNumber: isUndefined(phoneNumber) ? state.phoneNumber : phoneNumber,
        roleName: isUndefined(roleName) ? state.roleName : roleName,
        statusText: isUndefined(statusText) ? state.statusText : roleName
      }))
    },
    removeUser: () => {
      // Reset user data
      set(() => ({
        ...DEFAULT_USER_STORE_STATES
      }))
    }
  }
}

const useUserStore = create(userStore)

export default useUserStore
