import { RouteObject } from 'react-router-dom'

// Routes
const adminRoutes: RouteObject = {
  path: '/admin',
  lazy: async () => {
    const { default: Component } = await import('@src/app/pages/admin')
    return { element: <Component /> }
  },
  children: [
    {
      index: true,
      lazy: async () => {
        const { default: Component } = await import('@src/app/pages/admin/pages/home')
        return { element: <Component /> }
      }
    },
    // {
    //   path: '/admin/users',
    //   lazy: async () => {
    //     const { default: Component } = await import('@src/app/pages/admin/pages/user')
    //     return { element: <Component /> }
    //   }
    // },
    // {
    //   path: '/admin/statistics/individual',
    //   lazy: async () => {
    //     const { default: Component } = await import('@src/app/pages/admin/pages/individual-statistic')
    //     return { element: <Component /> }
    //   }
    // },
    // {
    //   path: '/admin/statistics/unit',
    //   lazy: async () => {
    //     const { default: Component } = await import('@src/app/pages/admin/pages/unit-statistic')
    //     return { element: <Component /> }
    //   }
    // },
    // {
    //   path: '/admin/exams',
    //   lazy: async () => {
    //     const { default: Component } = await import('@src/app/pages/admin/pages/exam')
    //     return { element: <Component /> }
    //   }
    // }
    {
      path: '/admin/activity-management/activities',
      lazy: async () => {
        const { default: Component } = await import('@src/app/pages/admin/pages/activities/activities/activities')
        return { element: <Component /> }
      }
    },
    {
      path: '/admin/activity-management/activity-detail/:activityCode',
      lazy: async () => {
        const { default: Component } = await import('@src/app/pages/admin/pages/activities/activities/activity-detail')
        return { element: <Component /> }
      }
    },
    {
      path: '/admin/activity-management/activity-form/:activityCode',
      lazy: async () => {
        const { default: Component } = await import('@src/app/pages/admin/pages/activities/activities/activity-form')
        return { element: <Component /> }
      }
    },
    {
      path: '/admin/activity-management/activity-form',
      lazy: async () => {
        const { default: Component } = await import('@src/app/pages/admin/pages/activities/activities/activity-form')
        return { element: <Component /> }
      }
    },
    {
      path: '/admin/activity-category-management/categories',
      lazy: async () => {
        const { default: Component } = await import(
          '@src/app/pages/admin/pages/activities/activity-categories/activity-categories'
        )
        return { element: <Component /> }
      }
    },
    {
      path: '/admin/activity-category-management/activity-category-form',
      lazy: async () => {
        const { default: Component } = await import(
          '@src/app/pages/admin/pages/activities/activity-categories/activity-category-form'
        )
        return { element: <Component /> }
      }
    }
  ]
}

export default adminRoutes
