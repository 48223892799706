// Core
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { useSearchParams } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'

// App
import { getName } from '@src/lib/utils'
// import { getName, splitFullName } from '@src/lib/utils'
import { GetMyInfoQueryType } from '@src/api/types/user'
import { useAuthStore, useUserStore } from '@src/stores'
import { getGetApiAuthMyInfoQueryKey, useGetApiAuthMyInfo } from '@src/api/endpoints/auth'

// Hook list
// Use query params
export const useQueryParams = () => {
  const [searchParams] = useSearchParams()
  const searchParamsObject = Object.fromEntries([...searchParams])
  return searchParamsObject
}

// Use logout
export const useLogout = () => {
  // Stores
  // Client
  const userStore = useUserStore(
    useShallow(({ setIsFetched, removeUser }) => ({
      setIsFetched,
      removeUser
    }))
  )

  const authStore = useAuthStore(
    useShallow(({ setIsLoggedIn, removeToken }) => ({
      setIsLoggedIn,
      removeToken
    }))
  )

  // Methods
  // Logout
  const handleLogout = useCallback(() => {
    authStore.removeToken()
    authStore.setIsLoggedIn(false)

    userStore.removeUser()
    userStore.setIsFetched(false)
  }, [authStore, userStore])

  return {
    handleLogout
  }
}

// Use fetch user information
export const useFetchUserInformation = () => {
  const queryClient = useQueryClient()

  // Stores
  // Client
  const userStore = useUserStore(useShallow(({ setIsFetched, setUser }) => ({ setIsFetched, setUser })))

  // Server
  const getMyInfoQuery = useGetApiAuthMyInfo<GetMyInfoQueryType>({
    query: {
      enabled: false
    }
  })

  // Methods
  // Handle fetch user information
  const handleFetchUserInformation = useCallback(async () => {
    // First refetch to mark as query is fetched, this refetching doesn't fetch api
    if (queryClient.getQueryData(getGetApiAuthMyInfoQueryKey())) {
      await getMyInfoQuery.refetch()
    }

    // Fetching
    const { data } = await getMyInfoQuery.refetch()
    const userInformationData = data?.responseData
    if (!userInformationData) {
      return
    }

    // const { lastName, middleName, firstName } = splitFullName(userInformationData.full_name)

    // // Set user store
    userStore.setUser({
      address: userInformationData.address,
      className: userInformationData.className,
      code: userInformationData.code,
      dateOfBirth: userInformationData.dateOfBirth,
      description: userInformationData.description,
      email: userInformationData.email,
      facultyName: userInformationData.facultyName,
      firstName: userInformationData.firstName,
      fullName: userInformationData.fullName,
      gender: userInformationData.gender,
      id: userInformationData.id,
      lastName: userInformationData.lastName,
      middleName: userInformationData.middleName,
      phoneNumber: userInformationData.phoneNumber,
      roleName: userInformationData.roleName,
      statusText: userInformationData.statusText
    })

    userStore.setIsFetched(true)
  }, [queryClient, getMyInfoQuery, userStore])

  return {
    isFetching: getMyInfoQuery.isFetching,
    isError: getMyInfoQuery.isError,
    handleFetchUserInformation
  }
}

// Use user full name
export const useUserFullName = () => {
  const userStore = useUserStore(
    useShallow(({ lastName, middleName, firstName }) => ({
      lastName,
      middleName,
      firstName
    }))
  )

  const userFullName = useMemo(() => {
    return getName({
      lastName: userStore.lastName ?? '',
      middleName: userStore.middleName ?? '',
      firstName: userStore.firstName ?? ''
    })
  }, [userStore])

  return userFullName
}

// Use skip initial effect
export const useSkipInitialEffect = (callback: CallableFunction, deps: unknown[]) => {
  // Ref
  const isInitialRenderRef = useRef(true)

  // Effect
  useEffect(() => {
    if (isInitialRenderRef.current) {
      isInitialRenderRef.current = false
      return
    }
    callback()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}
